import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"

import News from "../../../components/News"
import CaseStudeiesTopSection from "../../../components/CaseStudeiesTopSection"
import CaseStudeiesSimplified from "../../../components/CaseStudeiesSimplified"
import CaseStudyDescription from "../../../components/caseStudyDescription"

import headerImage from "../../../images/caseStudies/soharport.jpg"

const SOHARPortandFCPortal = ({ data }) => {
  return (
    <div className="CaseStudeies">
      <Layout pageTitle={"Vsiomate - Port and Freezone Container Portal for SOHAR Port"}
      descrip = {"Streamline your container operations with our Port and Freezone Container Portal. Track shipments, manage documentation, and improve visibility in real-time. Learn more!"}>
        <CaseStudeiesTopSection
          path="projects"
          headerData={{
            title: "Container management Solution integrated with Odoo",
            peragraph:
              "Increasing the capacity of vessel monitoring systems to deliver thorough real-time data and analytics, giving stakeholders important information for more strategic and knowledgeable decision-making throughout maritime operations.",
            image: headerImage,
          }}
          data={data}
        />
        <CaseStudyDescription
          caseStudyData={{
            ITitle: "Introduction",
            Idescription:
              "In the Sultanate of Oman, SOHAR Port is a renowned deep-sea port and freezone that is managed by the SOHAR Industrial Port Company (SIPC). With over US$26 billion in international investments, it's one of the fastest-growing port and free zone expansions globally. Strategically positioned at the center of major commercial routes connecting Europe and Asia, SOHAR Port is essential to promoting connectivity and global trade.",
            image: headerImage,
            data: [
              {
                Title: "keyReqProblem",
                Point1:
                  "SOHAR Port management required a monthly reporting off all the vessels used the Port or passed through, because at that time the current software was unable to use the existing data to present something valuable, whilst port was serving 100s if not 1000s of vessels per day.  ",
                Point2: "",
                Point3: "",
              },
              {
                Title: "Solution",
                Sdescription:
                  "Visiomate built an odoo based Container management solution, that integrates with Odoo 13, a web-based application was developed to enter the data for containers on the port and relevant customer data was maintained in Odoo 13 ERP.",
                Point1:
                  "Monthly reports were built which were submitted to the ministry of Oman for throughput of containers from Sohar port.",
                Point2:
                  "Workflows, notifications and reminders were implemented for customers to submit their data on allowed timeslots.",
                Point3: "",
              },
            ],
          }}
          data={data}
        />
        <CaseStudeiesSimplified
          TextData={{
            title: "Approach that Achieved Success",
            Data: [
              {
                peragraph: "Define Requirements",
                list: [
                  "Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.",
                  "List down the essential features and functionalities required.",
                ],
              },
              {
                peragraph: "Demo and Evaluation",
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process.",
                ],
              },
              {
                peragraph: "Scalability and Integration",
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations.",
                ],
              },
              {
                peragraph: "UAT & User Feedback",
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch.",
                ],
              },
            ],
          }}
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div>
  )
}

export default SOHARPortandFCPortal
export const query = graphql`
  query SOHARPortandFCPortalImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsHeaderImg: file(relativePath: { eq: "caseStudies/soharport.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondImg: file(relativePath: { eq: "caseStudies/soharport.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
